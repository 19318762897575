import { render, staticRenderFns } from "./BlockedTeam.vue?vue&type=template&id=48565cbc&scoped=true&"
import script from "./BlockedTeam.vue?vue&type=script&lang=js&"
export * from "./BlockedTeam.vue?vue&type=script&lang=js&"
import style0 from "./BlockedTeam.vue?vue&type=style&index=0&id=48565cbc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48565cbc",
  null
  
)

export default component.exports